@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply font-body;
    @apply text-base;
    @apply h-full;
  }

  #root {
    @apply bg-main;
    @apply text-white;
  }
}

@layer utilities {
  .placeholder-normal::placeholder {
    @apply font-normal;
    @apply text-sm;
    @apply font-body;
    @apply text-white/[0.5];
  }

  .link-item {
    @apply font-heading;
    @apply text-sm;
    @apply px-6 py-4;
    @apply rounded-lg;
    @apply inline-block;
    @apply whitespace-nowrap;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgba(99, 99, 99, 0.5);
    background: linear-gradient(
      90deg,
      rgba(251, 188, 94, 0.1) 0.13%,
      rgba(243, 161, 81, 0.1) 99.89%
    );
  }

  @media screen and (max-width: 991px) {
    .link-item {
      @apply px-3 py-2.5;
    }
  }

  .tag-item {
    @apply px-3 py-1;
    @apply rounded-lg;
    @apply font-semibold;
    transition: all 0.3s ease-in-out;
    /* border: 1px solid rgba(99, 99, 99, 0.50); */
    background: linear-gradient(
      90deg,
      rgba(251, 188, 94, 0.05) 0.13%,
      rgba(243, 161, 81, 0.05) 99.89%
    );
  }

  .link-item:hover {
    background: linear-gradient(
      90deg,
      rgba(251, 188, 94, 0.3) 0.13%,
      rgba(243, 161, 81, 0.3) 99.89%
    );
  }

  .tags {
    @apply rounded;
    @apply px-2;
    @apply text-sm;
    @apply absolute;
    @apply right-[14px];
    @apply top-[12px];
    @apply leading-relaxed;
    background: linear-gradient(
      90deg,
      rgba(177, 160, 234, 0.7) 0%,
      rgba(107, 254, 246, 0.7) 100%
    );
  }

  .music-icon {
    @apply -translate-x-1/2 -translate-y-1/2;
    @apply absolute;
    @apply top-1/2;
    @apply left-1/2;
    @apply p-4;
    @apply bg-white/[0.10];
    @apply rounded-full;
    @apply z-10;
  }
}

*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #1d1d1d;
  color: white;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgb(255 255 255);
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white !important;
  caret-color: white !important;
}

img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

:not(.showScrollbar)::-webkit-scrollbar {
  display: none;
}

/* Scrollbar modification */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgb(133, 128, 128);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(58, 54, 58, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(29, 28, 32, 0.6);
}

/* font size start*/
.fs5px {
  font-size: 0.8rem !important;
}

.fs10px {
  font-size: 1.2rem !important;
}

.fs12px {
  font-size: 1.3rem !important;
}

.fs14px {
  font-size: 1.4rem !important;
}

.fs16px {
  font-size: 1.6rem !important;
}

.fs18px {
  font-size: 1.8rem !important;
}

.fs20px {
  font-size: 2rem !important;
}

.fs24px {
  font-size: 2.5rem !important;
}

.fs28px {
  font-size: 3rem !important;
}

.fs32px {
  font-size: 4rem !important;
}

.font-bold {
  font-weight: bold !important;
}

/* font size end */
.width100 {
  width: 100%;
}

.width90 {
  width: 90%;
}

.width70 {
  width: 70%;
}

/* ----------------------flex related start*/
/* .flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}

.items-center {
  align-items: center !important;
} */

/* flex related end*/
/* ------------------------color start */
.c_white {
  color: white !important;
}

.c_gray {
  color: #979797 !important;
}

.c_25B39E {
  color: #25b39e;
}

.d_color {
  background: -webkit-linear-gradient(#fbbc5e, #f3a151);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* color end */
/* -------------------------background start */
.d_bg {
  /* background: rgb(16, 55, 74); */
  background: #1d1d1d;
}

.theme_bg {
  background: linear-gradient(#232323, #56514a, #232323);
}

.nav_bg {
  background-color: rgba(34, 34, 34, 0.5);
}

.bg_btn {
  /* background-color: #6768ff !important; */
  background: -webkit-linear-gradient(#fbbc5e, #f3a151);
}

.d-border {
  /* border: 1px solid #6768ff !important; */
  background: -webkit-linear-gradient(#fbbc5e, #f3a151);
  background-clip: text;
  -webkit-background-clip: border;
  -webkit-border-fill-color: transparent;
}

.rounded-3xl {
  border-radius: 6rem !important;
}

/* background end */
/* =--------------------------------text start */
.capitalize {
  text-transform: capitalize !important;
}

/* text end */
.card-hover_parent {
  transition: all 0.4s;
  border: none;
  border: 1px solid transparent;
}

.card-hover_img {
  height: 146px;
  width: 146px;
  border: none;
  transition: all 0.3s ease-out;
  margin: auto;
}

.card-hover_parent:hover {
  border: 1px solid #f3a051;
  background: linear-gradient(
    90deg,
    rgba(243, 160, 81, 0.1) 0%,
    rgba(255, 202, 100, 0.1) 100%
  );
}

.card-hover_parent:hover .card-hover_img {
  transform: scale(1.5) translateY(-30px);
}

.marketplace_tabs .MuiTabs-root {
  min-height: 0 !important;
  /* margin: 20px 0 0 22px; */
  /* display: flex !important; */
  /* column-gap: 10px !important; */
}

.marketplace_tabs .MuiTab-root {
  color: #7a7a7a;
  text-transform: capitalize;
  padding: 0px;
  padding-bottom: 5px;
  padding-left: 0.2px;
  /* border: 2px solid red; */
  min-width: 0px !important;
  min-height: 30px !important;
  /* margin: 10px 0; */
  margin-right: 20px;
  text-align: center;
}

.marketplace_tabs .Mui-selected {
  color: white !important;
  /* border: 2px solid green !important; */
  /* z-index: 99999; */
  position: relative;
  /* text-align: start; */
}

.marketplace_tabs .MuiTabs-indicator {
  background-color: -webkit-linear-gradient(#fbbc5e, #f3a151);
  /* top: 0 !important; */
  /* bottom: -1px !important; */
  /* display: none; */
  /* border: 1px solid red; */
  /* width: 100%;/ */
  /* color: white; */
  /* position: absolute; */
  /* top: 0 !important; */
  /* left: 0 !important; */
}

.entertainment_tabs .MuiTab-root {
  display: flex;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: start;
  padding-left: 14px;
  padding-top: 5px;
  border-radius: 8px;
  color: white;
  margin-bottom: 12px;
}

.entertainment_tabs .Mui-selected {
  z-index: 1;
}

.entertainment_tabs .MuiTabs-indicator {
  width: 100%;
  border-radius: 5px;
  background-color: -webkit-linear-gradient(#fbbc5e, #f3a151);
}

/* footer start*/
.footer_btn {
  color: #5e5e6d !important;
  min-width: 25px;
  height: 30px;
}

.footer_btn_img {
  width: 25px;
  height: 25px;
}

.events_card_gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

/* footer end */

/*-------------------------------- media query */
@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
}

/* @media (max-width: 56.25em) {
  html {
    font-size: 55% !important;
  }
} */

.btn_hover_effect:hover {
  background-color: #6768ff !important;
  border: 1px solid #6768ff;
}

.btn_hover_effect_opposite:hover {
  background-color: transparent !important;
  border: 1px solid #6768ff;
  /* color: white !important; */
}

.icon_hover_effect:hover {
  color: #6083ff !important;
}

.slideCarousel {
  width: 100%;
}

.thumbnail {
  height: 150px;
  width: 150px;
}

.MuiBadge-badge {
  font-size: 12px !important;
}

.css-3joow0 {
  /* border-bottom: none !important; */
  border-color: transparent !important;
}

.active {
  background-color: transparent;
  border-color: #fbbc5e !important;
}

.btn-gradient,
.btn-outline:hover {
  background: linear-gradient(90deg, #fbbc5e 0.13%, #f3a151 99.89%);
}

.btn-gradient:hover {
  background: #db9149;
}

.inner-html-headings h4 {
  font-size: 10.72px !important;
  font-weight: bold !important;
}

.inner-html-headings h5 {
  font-size: 13.28px !important;
  font-weight: bold !important;
}

.inner-html-headings h4 {
  font-size: 16px !important;
  font-weight: bold !important;
}
.inner-html-headings h3 {
  font-size: 18.72px !important;
  font-weight: bold !important;
}
.inner-html-headings h2 {
  font-size: 24px !important;
  font-weight: bold !important;
}
.inner-html-headings h1 {
  font-size: 32px !important;
  font-weight: bold !important;
}

nav {
  position: sticky;
  top: 0;
  z-index: 50;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
}

.nav-logo-container {
  width: 209px;
  height: 48.12px;
}

.nav-logo-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.loader {
  display: inline;
  width: 2rem;
  height: 2rem;
  color: rgb(229 231 235);
  animation: spin 1s linear infinite;
  fill: #e2931d;
}

.hero-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-image-container {
  position: relative;
  height: auto;
}

.fstr-image-container {
  position: relative;
  height: auto;
}

.hero-image-container img {
  /* height: 400px; */
  width: 100%;
  object-fit: cover;
}

.fstr-image-container img {
  height: 0px;
  width: 100%;
  object-fit: cover;
}

.hero-text-container {
  position: relative;
  padding: 64px 42px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 40px;
}

.hero-text {
  font-family: "Krona One", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}

.hero-text-desc,
.list-item-desc {
  font-size: 12px;
  line-height: 26.24px;
  text-align: center;
  font-weight: 500;
}

.fstr-text {
  font-size: 16px;
  line-height: 26.24px;
  text-align: center;
  font-weight: 500;
  color: #fbbc5e;
}

.shadow-frame {
  opacity: 0.5;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(255, 233, 201, 0.6),
    rgba(76, 0, 255, 0)
  );
  background-color: #ffe9c9;
  filter: blur(250px);
  -webkit-filter: blur(250px);
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateZ(0);
  position: absolute;
  width: 250px;
  height: 250px;
  pointer-events: none;
}

.hero-shadow {
  top: -40%;
  right: 15%;
  border: 2px solid red;
}

.btn {
  padding: 12px 24px;
  font-size: 12px;
  background: linear-gradient(90deg, #fbbc5e 0.13%, #f3a151 99.89%);
  color: black;
  font-weight: 700;
  border-radius: 8px;
  text-decoration: none;
  transition: all;
}

.download-btn {
  padding: 12px 24px;
  font-size: 12px;
  /* background: linear-gradient(90deg, #fbbc5e 0.13%, #f3a151 99.89%); */
  color: #fbbc5e;
  font-weight: 700;
  border-radius: 8px;
  text-decoration: none;
  transition: all;
  border: 1px solid #fbbc5e;
  cursor: pointer;
  display: flex;
  /* margin-bottom: 20px; */
  column-gap: 10px;
}

.btn:hover {
  background: #db9149;
}

.download-btn:hover {
  background: linear-gradient(90deg, #fbbc5e 0.13%, #f3a151 99.89%);
  color: black;
}

.carousel-container {
  padding-top: 0px;
}

.glider-container {
  position: relative;
  padding: 64px 0;
  overflow-x: hidden;
}

.glider-item {
  position: relative;
  width: 360px;
  height: 313px;
  margin: 0 16px;
}

@media (max-width: 1024px) {
  /* For tablets and small desktops */
  .glider-item {
    width: 300px;
    height: 260px;
  }
}

@media (max-width: 768px) {
  /* For tablets in portrait mode and large phones */
  .glider-item {
    width: 240px;
    height: 210px;
  }
}

@media (max-width: 480px) {
  /* For small mobile screens */
  .glider-item {
    width: 200px;
    height: 175px;
  }
}

.glider-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.glider-item p {
  position: absolute;
  left: 0;
  bottom: 0;
  text-decoration: none;
  display: block;
  width: 100%;
  background-color: rgba(134, 119, 94, 0.9);
  backdrop-filter: blur(4px);
  color: white;
  text-align: left;
  padding-left: 20% !important;
  font-size: 12px;
  padding: 16px 0;
  transition: all 0.2s ease;
}

.glider-item p:hover {
  filter: brightness(110%);
}

.glider-prev {
  position: absolute !important;
  top: 40% !important;
  left: 50px !important;
  background-color: black !important;
  display: grid;
  place-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.glider-prev svg {
  margin-right: 2px;
}

.glider-next {
  position: absolute !important;
  top: 40% !important;
  right: 50px !important;
  background-color: black !important;
  display: grid;
  place-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.glider-next svg {
  margin-left: 2px;
}

.list-container {
  margin-top: 128px;
}

.list-item {
  position: relative;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: clip;
}

.list-item:not(:nth-child(1)) {
  margin-top: 50px;
}

.list-image-container {
  width: 100%;
  height: 371px;
  border-radius: 20px;
}

.list-image-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 20px;
}

.list-text-container h2,
.token-info-container h2 {
  font-size: 32px;
  line-height: 60px;
  font-weight: 400;
  font-family: "Krona One", sans-serif;
  text-align: center;
}

.list-shadow {
  top: -100px;
  left: 0;
  height: 200px;
  width: 200px;
}

.list-item:nth-child(2) .list-shadow {
  left: 70%;
  top: 50px;
}

.unlock-text-container {
  margin: 150px auto 60px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.unlock-text-container-new {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.token-info-container,
.layout-info-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: auto;
  max-height: 877px;
}

.token-info-container img {
  /* height: 100%; */
  /* margin-right: 100px; */
  width: 85%;
  object-fit: cover;
}

.layout-info-container img {
  /* height: 100%; */
  width: 95%;
  object-fit: cover;
}

.token-info-container h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.token-text-container {
  position: relative;
  margin: 150px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.token-text-shadow {
  left: -50%;
  top: -60%;
}

.team-container {
  position: relative;
  overflow-x: clip;
}

.team-shadow-1 {
  right: 0%;
  top: 0;
}
.team-shadow-2 {
  left: 0;
  bottom: -30%;
  height: 150px;
}

.team-members-container {
  margin: 64px auto 150px auto;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 60px;
}

.team-member {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.advisor-member {
  height: 1000px;
}

.executive1 {
  height: 650px;
}

.member-img-container {
  height: 300px;
  width: 300px;
}

.member-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.team-member h3,
.member-role {
  font-family: "Krona One", sans-serif;
  text-align: center;
}

.team-member h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
}

.member-role {
  color: #fbbc5e;
  font-size: 12px;
}

.member-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.member-text-container p {
  margin-top: 16px;
  text-align: center;
  line-height: 26.24px;
}

.team-executive-link {
  display: flex;
  justify-content: center;
  gap: 15px;
}

footer {
  width: 100vw;
  background-color: black;
  padding: 52px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 30;
}
.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  z-index: 30;
}

.footer-link {
  text-decoration: none;
  color: white;
  font-size: 12px;
  line-height: 19.68px;
  transition: 0.2s color ease;
}

.footer-link:hover {
  color: #fbbc5e;
  stroke: #fbbc5e;
}

.footer-icon {
  margin-top: 4px;
}

/* .carousel-section .slick-track {
  margin-left: 0px !important;
} */

.btn-primary {
  color: rgba(37, 37, 36, 1) !important;
  text-transform: none !important;
  background: linear-gradient(rgba(251, 188, 94, 1), rgba(243, 161, 81, 1));
  border-radius: 8px;
  font-weight: 700 !important;
  min-width: 109px;
  min-height: 37px;
  padding: 12px, 48px, 12px, 48px;
  margin-top: 10px;
  margin-right: 10px;
  border: none;
}

.btn-primary:hover {
  /* color: rgba(255, 255, 255, 1) !important; */
  background: linear-gradient(rgba(251, 188, 94, 1), rgb(212, 126, 40));
}

.btn-secondary {
  color: rgba(255, 255, 255, 1);
  text-transform: none;
  background: rgba(122, 122, 122, 1);
  border-radius: 8px;
  font-weight: 700;
  min-width: 109px;
  min-height: 37px;
  padding: 12px, 48px, 12px, 48px;
  margin-top: 10px;
  margin-right: 10px;
}

.btn-secondary:hover {
  color: rgba(255, 255, 255, 1);
  background: rgba(204, 94, 61, 1);
}

.form-checkbox:checked:before {
  background-color: yellow !important;
}

@media (min-width: 500px) {
  .btn {
    font-size: 16px;
  }
  .download-btn {
    font-size: 16px;
  }
}
@media (min-width: 576px) {
  .glider-item {
    width: 560px !important;
  }

  .unlock-text-container,
  .token-text-container {
    width: 70%;
  }
}

@media (min-width: 1280px) {
  .glider-item {
    width: 395px !important;
  }
}

@media (min-width: 1536px) {
  .glider-item {
    width: 480px !important;
  }
}

@media (min-width: 1800px) {
  .glider-item {
    width: 580px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1535px) {
  .glider-item {
    margin: 0 35px !important;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .unlock-text-container,
  .token-text-container {
    margin: 80px 20px 30px 20px;
  }
  .team-container {
    position: static;
  }
  .mobile-team {
    margin-top: 80px !important;
  }
  .team-members-container {
    margin: 64px auto 60px auto;
  }

  .list-container {
    margin-top: 70px;
  }
  .glider-container {
    padding: 20px 0;
  }
  .executive1 {
    height: auto;
  }
  .advisor-member {
    height: auto;
  }
  footer {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .team-members-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 50%;
    margin: 64px auto 0px auto;
  }
  .team-container {
    position: static;
  }
  .advisor-member {
    max-width: 47%;
  }
}

@media (min-width: 300px) and (max-width: 370px) {
  .btn {
    padding: 7px 14px;
  }
}

@media (min-width: 768px) {
  nav {
    padding: 16px 48px;
  }

  .hero-image-container {
    height: 450px;
  }
  .fstr-image-container {
    height: 450px;
  }
  .hero-text-container {
    padding: 128px 42px;
    width: 70%;
    margin: 0 auto;
  }
  .hero-text {
    font-size: 40px;
    line-height: 50px;
  }
  .hero-text-desc {
    font-size: 16px;
    line-height: 26.24px;
  }
  .fstr-text {
    font-size: 20px;
    line-height: 26.24px;
    text-align: center;
    font-weight: 500;
    color: #fbbc5e;
    margin-bottom: 20px;
  }
  .shadow-frame {
    width: 500px;
  }
  .carousel-container {
    padding-top: 0px;
  }
  .list-item h2,
  .token-info-container h2 {
    font-size: 48px;
  }

  .list-item-desc {
    font-size: 16px;
  }
  .last-adv-mar {
    /* margin-bottom: 620px; */
  }
  .team-members-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .team-member h3 {
    font-size: 28px;
  }

  .member-role {
    font-size: 16px;
  }

  footer {
    gap: 32px;
  }
}

@media (min-width: 1024px) {
  nav {
    padding: 16px 96px;
  }
  .hero-image-container {
    height: 550px;
  }
  .fstr-image-container {
    height: 550px;
  }
  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 64px;
    width: 100%;
    margin: 0 auto;
  }
  .advisor-member {
    max-width: 47%;
  }
  .list-item:not(:nth-child(1)) {
    margin-top: 150px;
  }

  .list-item:nth-child(even) {
    flex-direction: row-reverse;
  }

  .list-image-container {
    max-width: 560px;
  }
  .list-text-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .list-text-container h2,
  .list-item-desc {
    text-align: start;
  }
  .list-item:not(:nth-child(1)) {
    margin-top: 100px;
  }
}
@media (min-width: 1280px) {
  .hero-image-container {
    height: 700px;
  }
  .fstr-image-container {
    height: 700px;
  }
  .advisor-member {
    max-width: 47%;
  }
}
@media (min-width: 1536px) {
  .hero-image-container {
    height: calc(98vh);
    max-height: 857px;
  }
  .fstr-image-container {
    height: calc(98vh);
    max-height: 857px;
  }
  .advisor-member {
    max-width: 47%;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1536px) and (max-width: 1800px) {
  .sidequest-button {
    top: 300px !important;
    left: 180px !important;
  }
  .sidequest-para {
    top: 330px !important;
    left: 95px !important;
  }
}
